import 'tailwindcss/tailwind.css'
import '@starchive/typography/fonts.css'
import '../styles.css'
import '@starchive/jasmine/jasmine.css'
import '@starchive/previewer/styles.css'
import '@starchive/media-player/styles.css'

import { StrictMode } from 'react'

import Head from 'next/head'
import type { AppProps as NextAppProps } from 'next/app'

import { AwaitTypography } from '@starchive/typography'

export type TAppProps = NextAppProps & {
  err?: Error
}

const ShareApp = ({ Component, pageProps, err }: TAppProps) => {
  return (
    <StrictMode>
      <Head>
        <title>Do. More. Creating.</title>

        <meta property='og:title' content='Starchive Sharing' />

        <meta
          property='og:description'
          content='Files were shared with you via Starchive.'
        />

        <meta
          property='og:image'
          content='https://starchive.io/files/app/starchive-url-bookmark-thumb-signup.png'
        />

        <meta property='og:type' content='website' />
      </Head>

      <AwaitTypography>
        <>
          {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
          <Component {...pageProps} err={err} />
        </>
      </AwaitTypography>
    </StrictMode>
  )
}

export default ShareApp
