import { ReactNode, useEffect, useState } from 'react'

export function AwaitTypography({
  children,
}: {
  children: ReactNode
}): JSX.Element | null {
  const isReady = useTypography()

  if (!isReady) {
    return null
  }

  return <>{children}</>
}

/**
 * To avoid FOUT, this hook is necessary in the entry point of your React package.
 *
 * @see https://dev.to/fyfirman/how-to-fix-fout-flash-of-unstyled-text-in-react-1dl1
 * @see https://github.com/fontsource/fontsource/issues/83
 * @returns Whether the font is ready to be used or not.
 */
export function useTypography(): boolean {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    document.fonts
      .load('12px Plus Jakarta Sans')
      .then((result) => result.length > 0 && setIsReady(true))
  }, [])

  return isReady
}
